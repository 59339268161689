.c-pointer {
    cursor: pointer;
}

.text-left {
    text-align: left !important;
}

.text-right {
    text-align: right !important;
}
