// Basic Input

.form-control {
    // border-radius: 0;
    background: $white;
    border: 0.0625rem solid $border;
    height: 3rem;
    padding: 0.3125rem 1.25rem;
    color: $dark;
    border-radius: $radius;
    font-size: 1rem;

    &:hover, &:focus, &.active {
        box-shadow: none;
        background: $white;
        color: $dark;
    }

    &:focus {
        border-color: var(--primary);
    }

    &.solid {
        background: $body-bg;
        border-color: #ececec;
        color: #000;

        &[type="file"] {
            padding: 6px 10px;
        }
    }
}


.input-rounded {
    border-radius: 6.25rem;
}


// Input Color
[data-theme-version="light"],
[data-theme-version="dark"] {
    .input-primary {
        .form-control {
            border-color: var(--primary);
        }

        .input-group-text {
            background-color: var(--primary);
            color: $white;
        }
    }

    .input-danger {
        .form-control {
            border-color: $danger;
        }

        .input-group-text {
            background-color: $danger;
            color: $white;
        }
    }

    .input-info {
        .form-control {
            border-color: $info;
        }

        .input-group-text {
            background-color: $info;
            color: $white;
        }
    }

    .input-success {
        .form-control {
            border-color: $success;
        }

        .input-group-text {
            background-color: $success;
            color: $white;
        }
    }

    .input-warning {
        .form-control {
            border-color: $warning;
        }

        .input-group-text {
            background-color: $warning;
            color: $white;
        }
    }
}

// Input Outline
[data-theme-version="light"],
[data-theme-version="dark"] {
    .input-primary-o {
        .form-control {
            border-color: var(--primary);

        }

        .input-group-text {
            background-color: transparent;
            border-color: var(--primary);
            color: var(--primary);
        }
    }

    .input-danger-o {
        .form-control {
            border-color: $danger;
        }

        .input-group-text {
            background-color: transparent;
            border-color: $danger;
            color: $danger;
        }
    }

    .input-info-o {
        .form-control {
            border-color: $info;
        }

        .input-group-text {
            background-color: transparent;
            border-color: $info;
            color: $info;
        }
    }

    .input-success-o {
        .form-control {
            border-color: $success;
        }

        .input-group-text {
            background-color: transparent;
            border-color: $success;
            color: $success;
        }
    }

    .input-warning-o {
        .form-control {
            border-color: $warning;
        }

        .input-group-text {
            background-color: transparent;
            border-color: $warning;
            color: $warning;
        }
    }
}

// Input Size/
// .form-control{
// min-height: 2.5rem;
// }
// .form-control-sm{
//     min-height: 2.25rem;
// }
// .form-control-lg{
//     min-height: 3.25rem;
// }


// Input Group
.input-group-text {
    background: #d7dae3;
    border: 0.0625rem solid #f5f5f5;
    min-width: 3.125rem;
    display: flex;
    justify-content: center;
    padding: 0.532rem 0.75rem;

    i {
        font-size: 1rem;
    }
}

.form-file-label {
    height: 2.5rem;
    padding: 0.5rem 0.75rem;
}

.input-group-prepend .btn,
.input-group-append .btn {
    z-index: 0;
}

.custom-select {
    background: none;
    // height: 2.8125rem;
    border-color: $border;
    color: $dark;
    // border-radius: 0rem
    &:focus {
        box-shadow: none;
        border-color: var(--primary);
        color: var(--primary);
    }
}

.form-file-label {
    // height: 2.8125rem;
    // border-radius: 0rem
    background: #656C73;
    white-space: nowrap;
    color: $white;
    @at-root [data-theme-version="dark"] & {
        background: $d-border;
        border-color: $d-border;
        color: $body-color;
    }
}

.custom_file_input {
    .input-group-prepend,
    .input-group-append {
        // height: 2.8125rem;
    }

    .form-file-label::after {
        height: 100%;
        // line-height: 2.4;
    }
}


.form-control:disabled,
.form-control[readonly] {
    background: $white;
    opacity: 1;
}

.form-file {
    border: 0.0625rem solid $border;
    background: $white;
    @at-root [data-theme-version="dark"] & {
        background: $d-bg;
        border-color: $d-border;
    }
}

.input-group {
    > .form-control-plaintext,
    > .form-select,
    > .form-file {
        position: relative; // For focus state's z-index
        flex: 1 1 auto;
        // Add width 1% and flex-basis auto to ensure that button will not wrap out
        // the column. Applies to IE Edge+ and Firefox. Chrome does not require this.
        width: 1%;
        margin-bottom: 0;

    }

    & > .form-file {
        display: flex;
        align-items: center;

        &:not(:last-child) .form-file-label,
        &:not(:last-child) .form-file-label::after {
            @include border-right-radius(0);
        }

        &:not(:first-child) .form-file-label {
            @include border-left-radius(0);
        }
    }
}


.select2-container--default .select2-selection--multiple .select2-selection__choice {
    border-radius: $radius;
}

.form-file {
    .form-control {
        margin: 0;
        border-radius: 0;
        border: 0;
        height: auto;
    }
}
