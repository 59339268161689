

//heart Blast Effect

.heart-blast {
    background-position: -105rem 0!important;
    transition: background 1s steps(28);
}

.heart {
    width   	: 3.75rem;
    height  	: 3.75rem;
    display 	: inline-block;
    background  : url('/images/like.png');
    cursor      : pointer;
    margin      : -1.5625rem -0.9375rem;
}
.header-profile{
	position:relative;
	margin-left: 1.875rem;
	padding-left: 1.875rem;
	.nav-link{
		position:relative;
		border-radius: 3rem;
		padding:0.1875rem;
		img{
			height:3.75rem;
			width:3.75rem;
			border-radius:4.25rem;

            @include respond('laptop') {
                width: 2.75rem;
                height: 2.75rem;
            }
		}
	}

    @include respond('phone'){
        margin-left: 0.5rem;
    }
}
.plus-box {
    padding: 0.625rem 1.1875rem 1.875rem;
    position: relative;
    overflow: hidden;
    margin: 0rem 2.5rem;
    border-radius: 1rem;
    align-items: center;
    margin-bottom: 1.5625rem;
    margin-top: 1.625rem;
    background-image: url('/images/circles.png');
    background-repeat: no-repeat;
    background-position: right top;
	box-shadow:0 0.25rem rgba(0, 0, 0, 0.04);
}

.header-profile{
	.nav-link{
		padding-right: 0!important;
		padding-left: 0!important;
	}
}

.ck.ck-editor__editable_inline>:first-child{
	height:9.375rem;
}
.textarea_editor {
	height:7.5rem;
}
.current-icon{
	a{
		height: 3.25rem;
		width: 3.25rem;
		background:var(--primary);
		display: block;
		border-radius: 3.125rem;
		line-height: 3.25rem;
		text-align: center;
		color: white;
		font-size: 1.5625rem;
		transform: rotate(45deg);
	}
}
.current-tab{
	margin-bottom:2rem;
	.nav-tabs{
		border:0;
		.nav-item{
			.nav-link{
				border:0;
				border-radius:3.125rem;
				padding: 0.75rem 1rem;
				font-size:1rem;
				font-weight:400;
				color:#717579;
				&.active{
					background:#EEEEEE;
				}
			}
		}
	}
}
.bg-progradient{
	background: linear-gradient(212.43deg, #005445 19.43%, #00A15D 87.63%);
}
.manage-project{
	position:relative;
	overflow: hidden;
	&:before{
		position:absolute;
		content:"";
		top:0;
		background-image: url('/images/ellipse.png');
		height: 100%;
		width: 100%;
		background-repeat: no-repeat;
		left: 1.25rem;
	}
	&:after{
		position:absolute;
		content:"";
		background-image: url('/images/ellipse2.png');
		height: 100%;
		width: 100%;
		background-repeat: no-repeat;
		background-position: 71% 130%;
	}
	a{
		position:relative;
		z-index:1;
	}
}
.donut-chart-sale small, .donut-chart-sale .small {
	font-size: 1rem;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    display: flex;
    align-items: center;
    top: 0;
    justify-content: center;
    font-weight: 600;

}
.default-progress{
	height:0.625rem;
}
.bg-ombe{
	background:#9D8160;
}
.bg-pushup{
	background:#ED368E;
}
.bg-kleon{
	background:#833AE0;
}
.bg-biji{
	background:#2228B4;
}
.slide-icon{
	span{
		height:4.25rem;
		width:4.25rem;
		border-radius:4.25rem;
		line-height:4.25rem;
		text-align:center;
		display:block;

	}
}
.bgl-blue{
	background:rgba($blue,0.1);
}
.slider-button{
	span{
		padding:0.625rem 1.25rem;
		border-radius:0.25rem;
	}
}
.slide-info{
	padding: 0.9375rem 1.5625rem;
    border: 0.0625rem solid #EEEEEE;
    border-radius: 0.75rem;
	margin: 0.25rem 0.625rem;
}
.msg-bx{
	border-bottom:0.0625rem solid var(--rgba-primary-1);
	padding: 1rem 1rem;
	position:relative;
	.msg{
		.image-box{
			height: 3.125rem;
			width: 3.125rem;
			position:relative;
			img{
				height:100%;
				width:100%;
				border-radius:3.125rem;
				object-fit:cover;
			}
			&.active{
				&:after{
					height:1rem;
					width:1rem;
					border-radius:3.125rem;
					background:$success;
					border:0.0625rem solid $white;
					bottom: 0;
					right: -0.3125rem;
					position:absolute;
					content:"";
				}
			}

		}

	}

}

.react-logo{
	fill:var(--primary);
}

.box-cate-via {
    transition: all linear .2s;

    .card-header {
        min-height: 54px;

        h4 {
            font-size: 16px;
            text-align: center;
            color: $white;
            font-weight: 900;
            margin: 0;
            line-height: 1.3;
            @include line-clamp(3, 62px);

            a {
                color: inherit !important;
            }
        }
    }

    &:hover {
        box-shadow: $box-shadow-lg;
    }


}

@media (max-width: 450px){
    .box-cate-via {
        .list-group-item {
            display: block !important;
            padding-left: 15px !important;
            position: relative;

            .text-success {
                position: absolute;
                left: 0;
                top: 5px;
            }
        }
    }

    [id*="list-via-"] {
        .row {
            margin-left: -5px;
            margin-right: -5px;
        }

        [class*="col-"] {
            padding-left: 5px;
            padding-right: 5px;
        }
    }
}
