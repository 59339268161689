/* Editable */

#preloader{
    background-color: rgba($white, 0.3);
    padding: 0;
    margin: 0;
    height: 100%;
    position: fixed;
    z-index: 99999;
    width: 100%;
    align-items: center;
    justify-content: center;
    display: none;
    visibility: hidden;
    opacity: 0;
    transition: all linear .2s;

    &.show {
        display:flex;
        visibility: visible;
        opacity: 1;
    }


    .lds-ripple {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;
    }
    .lds-ripple div {
        position: absolute;
        border: 4px solid var(--primary);
        opacity: 1;
        border-radius: 50%;
        animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    }
    .lds-ripple div:nth-child(2) {
        animation-delay: -0.5s;
    }
    @keyframes lds-ripple {
        0% {
            top: 36px;
            left: 36px;
            width: 0;
            height: 0;
            opacity: 1;
        }
        100% {
            top: 0px;
            left: 0px;
            width: 72px;
            height: 72px;
            opacity: 0;
        }
    }
}

$d-bg: #161717;
[data-theme-version="dark"] {
    #preloader{
        background-color: $d-bg;
    }
}
