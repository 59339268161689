//Google font
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,800');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700');
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700');
@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700');
@import url('https://fonts.googleapis.com/css?family=Nunito:400,600,700');


// @import url('./../icons/helveticaNeue/css/helveticaNeue.css');
//@import url('./../icons/simple-line-icons/css/simple-line-icons.css');
//@import url('./../icons/font-awesome/css/all.min.css');
//@import url('./../icons/material-design-iconic-font/css/materialdesignicons.min.css');
//@import url('./../icons/themify-icons/css/themify-icons.css');
//@import url('./../icons/line-awesome/css/line-awesome.min.css');
//@import url('./../icons/avasta/css/style.css');
//@import url('./../icons/flaticon/flaticon.css');
//@import url('./../icons/flaticon_1/flaticon_1.css');
//@import url('./../icons/icomoon/icomoon.css');
//@import url('./../icons/bootstrap-icons/font/bootstrap-icons.css');


@for $i from 1 through 9 {
    .fw-#{$i * 100}{
        font-weight: $i * 100
    }
}








