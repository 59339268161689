.contacts-search{
	width: 35rem;
	.form-control{
		border-color:#EEEEEE;	
		border-right: 0;
		background:#EEEEEE;
		border-top-left-radius: 3.125rem;
		border-bottom-left-radius: 3.125rem;
		height: 60px;
	}
	.input-group-text{
		border-color:#EEEEEE;	
		background:#EEEEEE;
		border-left: 0;
		height: 60px;
		border-radius: 3.125rem;
		padding-right:20px;
		i{
			font-size: 1.5rem;	
		}
	}
}
.contact-bx{
	border-width: 2px;
	.card-header{
		position: absolute;
		right: 0;
		top: 0;	
	}
	.image-bx{
		display: inline-block;
		position: relative;
		margin-bottom: 10px;
		img{
			width:90px;
			height:90px;
		}
		
		span{
			&.active{
				height: 24px;
				width: 24px;
				position: absolute;
				bottom: -4px;
				right: 3px;
				border-radius: 2rem;
				background:$success;
				border: 4px solid #fff;	
			}	
		}
	}
	.user-meta-info{
		li{
			margin:25px 0;
			a{
				border: 1px solid var(--rgba-primary-2);
				border-radius: 50px;
				height: 24px;
				width: 24px;
				line-height: 24px;
				padding: 5px 8px;
				font-size: 16px;
				color:var(--primary);
				margin-right:15px;
			}	
		}	
	}
	&:hover{
		box-shadow: rgba(136, 108, 192, 0.2) 0px 7px 29px 0px;	
		.user-meta-info{
			li{
				a{
					background:var(--primary);
					color:$white;
				}
			}	
		}
	}
}
.progect-pagination{
	.pagination{
		.page-indicator{
			.page-link{
				padding: 14px 21px;
				font-size: 16px;
				border: 1px solid var(--primary);
				border-radius: 50px;	
				color:var(--primary);
				&:hover{
					color:$white	
				}
				@include respond ('phone'){
					padding: 0px 10px;
					line-height: 30px;
					font-size: 14px;
				}
			}	
		}	
		.page-item{
			display:flex;	
			font-size: 16px;
			border: 1px solid var(--primary);
			border-radius: 50px;	
			background:var(--rgba-primary-1);
			color:var(--primary);
			margin:0 5px;
			a{
				padding: 14px 21px;	
				font-size:18px;
				font-weight:500;
				&.active{
					background:var(--primary);
					color:$white;
					border-radius:50px;
				}
				@include respond ('phone'){
					padding: 6px 10px;
					font-size: 14px;
						
				}
			}
		}
	}	
}




//////project-page

.default-progress1{
	height:14px;	
}
.project-description{
	padding: 30px 0;
}
.message1{
	border: 2px solid;
    border-color: #EEEEEE;
    padding: 12px 2px;
    border-radius: 14px;	
	textarea{
		width:100%;
		border-width:2px;
		padding:15px;
		border-radius:14px;
		height:5rem!important;
		border:0;
		resize: none;
	}
	.msg-button{
		display: flex;
		align-items: center;
		justify-content: end;
		i{
			font-size:25px;
			color:var(--primary);
			
		}	
		a{
			padding: 10px 30px;
		}
	}
}
.comments{
	margin:30px 0;
	.dashboard-select{
		width: 231px;
		background:var(--rgba-primary-1);
		color:var(--primary);
		font-size:16px;
		font-weight:400;
		border-radius:50px;
		border:0;
		&:after{
			border-color:var(--primary);	
		}
		@include respond('phone'){
			width:200px;	
		}
	}	
}
.user-comment{
	padding: 15px 0;
    margin: 15px 0;
	img{
		height:50px;
		width:50px;
		border-radius:50px;
	}	
	span{
		color:$black;	
	}
}
